<template>
  <base-section
    id="latex-gloves"
  >
    <v-container class="fill-height px-4 py-0">
      <v-responsive
        class="d-flex align-center mx-auto"
        height="100%"
        width="100%"
      >
      <base-section-heading
        title="Natural Rubber Gloves"
      />
        <v-row>
          <v-col
            cols="12"
            sm="3"
            class="text-center"
          >
            <template
              v-for="(card, i) in cards"
            >
              <base-avatar-card-portfolio
                :key="i"
                align="center"
                v-bind="card"
                @click.native="fnChangeTab(card)"
              />
              <v-divider
                v-if="i + 1 !== cards.length"
                :key="`divider-${i}`"
                class="my-8"
              />
            </template>
          </v-col>

          <v-col
            cols="0"
            sm="1"
            class="text-center px-0 mx-0"
          >
            <v-responsive
              height="calc(100% - 16px)"
              class="mx-0 px-0"
            >
              <v-divider
                vertical
                dark
                class="mx-0 px-0"
              />
            </v-responsive>
          </v-col>

          <v-col
            cols="12"
            sm="8"
          >
            <base-subheading
              size="text-h5"
              :title="cards[currentTab].title"
              space="0"
              mobileSize="text-h6"
              class="text-uppercase"
            />

            <base-title
              class="primary--text"
              :title="cards[currentTab].location"
              tag="div"
            />
            <v-img
              :src="cards[currentTab].photo"
              height="300"
            />
            <br>
            <base-body
              :html="cards[currentTab].description"
            />
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <div class="text-center">
                  <v-btn
                    class="font-weight-bold fourth"
                    text
                    @click=fnOpenReadMore
                    color="white"
                    min-width="180"
                  >
                    Read More
                  </v-btn>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <div class="text-center">
                  <v-btn
                    class="font-weight-bold third"
                    text
                    @click=fnGoToContact
                    color="white"
                    min-width="180"
                  >
                    Request Sample
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row
              v-if="readMoreOpened"
              class="justify-center"
            >
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr
                      v-for="item in cards[currentTab].specs"
                      :key="item.name"
                    >
                      <td>{{ item.name }}</td>
                      <td
                        class="py-8"
                      >
                        <v-row>
                          {{ item.desc }}
                        </v-row>
                        <v-row>
                          <v-img
                            :src="item.logo1"
                            contain
                            max-width="60"
                          />
                          <v-img
                            :src="item.logo2"
                            contain
                            max-width="60"
                          />
                          <v-img
                            :src="item.logo3"
                            contain
                            max-width="60"
                          />
                        </v-row>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-row>
          </v-col>
        </v-row>
      </v-responsive>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionLatexGloves',
    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
    data: () => ({
      currentTab: 0,
      readMoreOpened: false,
      cards: [
        {
          no: 0,
          title: 'Latex Powdered Examination Gloves',
          description: 'Latex Powdered examination gloves for superior personal barrier protection<br><ul><li>Lightly powdered for fast and easy donning process.</li><li>Beaded cuff design and textured fingertips for confident, secure grip.</li><li>Provides barrier protection and stretchable properties for less hand fatigue and flexibility.</li><li>Tested and comply according to ASTM D3578 and EN455 standards.</li></ul>',
          location: 'Latex Powdered Examination Gloves',
          duration: 'November 2019',
          value: 'Project value: RM 6,400,000',
          flags: [
            require('@/assets/Latex - Application1.png'),
          ],
          photo: require('@/assets/Latex - Application1.png'),
          chosen: true,
          specs: [
            {
              name: 'Product Type',
              desc: 'Latex Powdered Examination Gloves (non sterile)',
            },
            {
              name: 'Color',
              desc: 'Natural color',
            },
            {
              name: 'Available Weight',
              desc: 'Various weight according to customer requirement.',
            },
            {
              name: 'Raw Material',
              desc: 'Natural Rubber Latex',
            },
            {
              name: 'Length',
              desc: 'Min.240mm',
            },
            {
              name: 'Single wall thickness (Finger)',
              desc: 'min 0.11mm (4.3 mil)',
            },
            {
              name: 'Powder content',
              desc: '≤ 120mg / glove',
            },
            {
              name: 'Protein residue content',
              desc: '< 200 µg/dm²',
            },
            {
              name: 'Quality',
              desc: 'AQL 1.5',
            },
            {
              name: 'Features',
              desc: 'Ambidextrous, Finger textured, Beaded Cuff',
            },
            {
              name: 'Finishing',
              desc: 'Online Chlorinated',
            },
            {
              name: 'Tensile Strength',
              desc: '18 MPa(Before Aging), 14 MPa(After Aging)',
            },
            {
              name: 'Elongation',
              desc: '650 %(Before Aging), 500 %(After Aging)',
            },
            {
              name: 'Shelf Life',
              desc: '5 years',
            },
            {
              name: 'Storage',
              desc: 'Store in a cool and dry place. Keep away from sunlight.',
            },
            {
              name: 'Latex Powdered Examination Gloves Standard',
              desc: 'ASTM D3578',
              logo1: require('@/assets/ASTM.png'),
            },
          ],
        },
        {
          no: 1,
          title: 'Latex Powder Free Examination Gloves',
          description: 'Latex Powder Free examination gloves are skin- friendly for protein allergy users.<br><ul><li>Replaced powder coating with chlorination / polymer coating for easy donning.</li><li>Strong and puncture resistance gloves which fit firmly on hands for better tactile sensitivity and excellent protection. </li><li>Tested and comply according to ASTM D3578 and EN455 standards.</li></ul>',
          location: 'Latex Powder Free Examination Gloves',
          duration: 'November 2019',
          value: 'Project value: RM 6,400,000',
          flags: [
            require('@/assets/Latex - Application2.png'),
          ],
          photo: require('@/assets/Latex - Application2.png'),
          chosen: false,
          specs: [
            {
              name: 'Product Type',
              desc: 'Latex Powder Free Examination Gloves (non sterile)',
            },
            {
              name: 'Color',
              desc: 'Natural color',
            },
            {
              name: 'Available Weight',
              desc: 'Various weight according to customer requirement.',
            },
            {
              name: 'Raw Material',
              desc: 'Natural Rubber Latex',
            },
            {
              name: 'Length',
              desc: 'Min.240mm',
            },
            {
              name: 'Single wall thickness (Finger)',
              desc: 'min 0.11mm (4.3 mil)',
            },
            {
              name: 'Powder content',
              desc: '≤ 2mg / glove',
            },
            {
              name: 'Protein residue content',
              desc: '< 50 µg/dm²',
            },
            {
              name: 'Quality',
              desc: 'AQL 1.5',
            },
            {
              name: 'Features',
              desc: 'Ambidextrous, Finger textured, Beaded Cuff',
            },
            {
              name: 'Finishing',
              desc: 'Online Chlorinated',
            },
            {
              name: 'Tensile Strength',
              desc: '18 MPa(Before Aging), 14 MPa(After Aging)',
            },
            {
              name: 'Elongation',
              desc: '650 %(Before Aging), 500 %(After Aging)',
            },
            {
              name: 'Shelf Life',
              desc: '5 years',
            },
            {
              name: 'Storage',
              desc: 'Store in a cool and dry place. Keep away from sunlight.',
            },
            {
              name: 'Latex Powder Free Examination Gloves Standard',
              desc: 'ASTM D3578',
              logo1: require('@/assets/ASTM.png'),
            },
          ],
        },
      ],
    }),
    methods: {
      fnOpenReadMore () {
        this.readMoreOpened = true
      },
      fnChangeTab (card) {
        this.currentTab = card.no
        this.cards[0].chosen = false
        this.cards[1].chosen = false
        card.chosen = true
        this.readMoreOpened = false
      },
      fnGoToContact () {
        this.$router.push('/contact')
      },
    },
  }
</script>
